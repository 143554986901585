//trip
export const getFeedbackStatus = (status) => {
  if (status === true) {
    return 'GOOD'
  } else if (status === false) {
    return 'BAD'
  } else {
    return 'Not Rated'
  }
}

export const getVehicleTypeAliasFromData = (data) => {
  let aliases = {
    S: 'Bike',
    P: 'Scooter',
    PP: 'Scooter Pro',
    E: 'Ebike',
    C: 'Super Coco',
    K: 'Kenota',
    M: 'Moped',
  }
  return data in aliases ? aliases[data] : null
}

export const exportKey = {
  TRIP: 0,
  RIDER: 1,
  REPORT: 2,
  IOT_LOG: 3,
}
